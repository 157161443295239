import { storeToRefs } from 'pinia'
import { useProfileStore } from '~/stores/profileStore'
import type { PersonalHygieneResult } from '~~/types/api/compute/personalHygiene'
import ComputeService from '~/services/ComputeService'

export const usePersonalHygieneStore = defineStore('personalHygiene', () => {
  const app = useNuxtApp()
  const pinia = app.$pinia
  const profileStore = useProfileStore(pinia)

  const { profile } = storeToRefs(profileStore)

  const residenceId = null

  const personalHygieneResultRef = ref<PersonalHygieneResult['result'] | null>(null)

  const update = async () => {
    if (!residenceId) {
      try {
        await profileStore.getProfile()

        if (!residenceId)
          return
      }
      catch (error) {
        if (isDev)
          console.error('usePersonalHygieneStore.update: profileStore.getProfile failed', error)
        return
      }
    }

    personalHygieneResultRef.value = await ComputeService.getPersonalHygieneResult(
      {
        residence_id: residenceId,
      },
      true,
    )
  }

  if (!personalHygieneResultRef.value && profile.value && residenceId)
    update() // initialize

  watchDebounced(
    profile,
    async () => {
      if (!profile.value || !residenceId) {
        personalHygieneResultRef.value = null
        return
      }
      update()
    },
    { debounce: 300, maxWait: 1000 },
  )

  const initialize = async () => {
    if (personalHygieneResultRef.value)
      return // already initialized
    await update()
  }

  const personalHygieneResult = computed(() => {
    assertNonNull(
      personalHygieneResultRef.value,
      'usePersonalHygieneStore.getPersonalHygieneResult was called before personalHygieneResult was initialized or the profile is null',
    )

    return personalHygieneResultRef.value
  })

  const compare = (input?: PersonalHygieneResult | PersonalHygieneResult['result']) => {
    assertNonNull(
      personalHygieneResultRef.value,
      'usePersonalHygieneStore.compare got called before personalHygieneResult was initialized or the profile is null',
    )

    if (!input) {
      const diff: PersonalHygieneResult['result'] = rfdc(personalHygieneResultRef.value)
      for (const resultKey of Object.keys(personalHygieneResultRef.value)) {
        if (resultKey === 'per_person') {
          for (const personId in personalHygieneResultRef.value.per_person) {
            for (const resultPPKey of Object.keys(personalHygieneResultRef.value.per_person[personId]))
              diff[resultKey][personId][resultPPKey] = 0
          }
        }
        else {
          diff[resultKey] = 0
        }
      }

      return diff
    }

    const inputResult = 'result' in input ? input.result : input
    const diff: PersonalHygieneResult['result'] = rfdc(personalHygieneResultRef.value)

    for (const resultKey of Object.keys(personalHygieneResultRef.value)) {
      if (resultKey === 'per_person') {
        for (const personId in personalHygieneResultRef.value.per_person) {
          for (const resultPPKey of Object.keys(personalHygieneResultRef.value.per_person[personId]))
            diff[resultKey][personId][resultPPKey] -= inputResult[resultKey][personId][resultPPKey]
        }
      }
      else {
        diff[resultKey] -= inputResult[resultKey]
      }
    }

    return diff as PersonalHygieneResult['result']
  }

  const fetchWithDeviation = async (deviationObj: any) => {
    return await ComputeService.getPersonalHygieneResult(deviationObj, false) // no refresh because it should already have the newest values in cache
  }

  return { initialize, personalHygieneResult, compare, fetchWithDeviation }
})
