import { default as _91_46_46_46slug_935oyfUMMOeWMeta } from "/opt/build/repo/app/pages/[...slug].vue?macro=true";
import { default as change_45passwordieN0Bq7BQGMeta } from "/opt/build/repo/app/pages/account/change-password.vue?macro=true";
import { default as indexkpZWM5d2B7Meta } from "/opt/build/repo/app/pages/account/index.vue?macro=true";
import { default as accounton9HXKIm8WMeta } from "/opt/build/repo/app/pages/account.vue?macro=true";
import { default as indexySMGYhsb0bMeta } from "/opt/build/repo/app/pages/artikelen/[slug]/index.vue?macro=true";
import { default as indexyX3mmPjpJTMeta } from "/opt/build/repo/app/pages/artikelen/index.vue?macro=true";
import { default as _91themeslug_93n2YDAivCEvMeta } from "/opt/build/repo/app/pages/artikelen/thema/[themeslug].vue?macro=true";
import { default as zoekenWraIWPWZmHMeta } from "/opt/build/repo/app/pages/artikelen/zoeken.vue?macro=true";
import { default as forgot_45passwordo19rVsgnAyMeta } from "/opt/build/repo/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginCOcTRg2g5mMeta } from "/opt/build/repo/app/pages/auth/login.vue?macro=true";
import { default as signupnZr5JCOvb0Meta } from "/opt/build/repo/app/pages/auth/signup.vue?macro=true";
import { default as verify_45emailwi0CVOgvscMeta } from "/opt/build/repo/app/pages/auth/verify-email.vue?macro=true";
import { default as _91_46_46_46slug_93hZadi4L8yZMeta } from "/opt/build/repo/app/pages/calculatoren/[...slug].vue?macro=true";
import { default as bronkhorstHP88keyCTpMeta } from "/opt/build/repo/app/pages/campagne/bronkhorst.vue?macro=true";
import { default as ddopgWsCQ1m1h6Meta } from "/opt/build/repo/app/pages/campagne/ddop.vue?macro=true";
import { default as supertesterai16RR25z0Meta } from "/opt/build/repo/app/pages/campagne/supertester.vue?macro=true";
import { default as bronkhorstctk76qkyCqMeta } from "/opt/build/repo/app/pages/collectief/bronkhorst.vue?macro=true";
import { default as ddop_45demorv217iN227Meta } from "/opt/build/repo/app/pages/collectief/ddop-demo.vue?macro=true";
import { default as supertesternnjvbDmbE7Meta } from "/opt/build/repo/app/pages/collectief/supertester.vue?macro=true";
import { default as contactgy53oYD1pgMeta } from "/opt/build/repo/app/pages/contact.vue?macro=true";
import { default as favorietenzaR7hahzVpMeta } from "/opt/build/repo/app/pages/favorieten.vue?macro=true";
import { default as feedbackvfHAfv3UD8Meta } from "/opt/build/repo/app/pages/feedback.vue?macro=true";
import { default as goalsvGrhNf24SbMeta } from "/opt/build/repo/app/pages/goals.vue?macro=true";
import { default as adresqrirFUqHKvMeta } from "/opt/build/repo/app/pages/huishouden/adres.vue?macro=true";
import { default as indexW6Dx7RoPIEMeta } from "/opt/build/repo/app/pages/huishouden/index.vue?macro=true";
import { default as huishoudenaWlIXMiRaZMeta } from "/opt/build/repo/app/pages/huishouden.vue?macro=true";
import { default as index9QtG0JCDh2Meta } from "/opt/build/repo/app/pages/index.vue?macro=true";
import { default as login_45for_45test_45sitexAN9eDmFX3Meta } from "/opt/build/repo/app/pages/login-for-test-site.vue?macro=true";
import { default as index2VknEjogkRMeta } from "/opt/build/repo/app/pages/mijn-woning/[space]/[calculator]/index.vue?macro=true";
import { default as indexesnFaWWtYLMeta } from "/opt/build/repo/app/pages/mijn-woning/[space]/index.vue?macro=true";
import { default as indexsRihZJSn5UMeta } from "/opt/build/repo/app/pages/mijn-woning/index.vue?macro=true";
import { default as indexeFF5gKHwf3Meta } from "/opt/build/repo/app/pages/mijn-woning/samenstellen/[space]/index.vue?macro=true";
import { default as indexiLLFluFCsYMeta } from "/opt/build/repo/app/pages/mijn-woning/samenstellen/index.vue?macro=true";
import { default as warmtevraagH0lLikUMYcMeta } from "/opt/build/repo/app/pages/mijn-woning/samenstellen/warmtevraag.vue?macro=true";
import { default as mijn_45woningRQTXE4MeowMeta } from "/opt/build/repo/app/pages/mijn-woning.vue?macro=true";
import { default as indexwF8nRBU3h2Meta } from "/opt/build/repo/app/pages/onboarding/index.vue?macro=true";
import { default as badYvdyzm8LEBMeta } from "/opt/build/repo/app/pages/plannen/bad.vue?macro=true";
import { default as douche4IQED3A1gSMeta } from "/opt/build/repo/app/pages/plannen/douche.vue?macro=true";
import { default as huishoudenpSbIOzDomQMeta } from "/opt/build/repo/app/pages/plannen/huishouden.vue?macro=true";
import { default as indexQapwhSvgkbMeta } from "/opt/build/repo/app/pages/plannen/index.vue?macro=true";
import { default as plannen7DtYZnORIjMeta } from "/opt/build/repo/app/pages/plannen.vue?macro=true";
import { default as previewaiHPjiVWGGMeta } from "/opt/build/repo/app/pages/preview.vue?macro=true";
import { default as indexEtKpbcV7B9Meta } from "/opt/build/repo/app/pages/profiel/create/index.vue?macro=true";
import { default as indexvgZu8CN9c4Meta } from "/opt/build/repo/app/pages/profiel/create/woning/index.vue?macro=true";
import { default as profielH5e06hO1SFMeta } from "/opt/build/repo/app/pages/profiel.vue?macro=true";
import { default as _91_46_46_46slug_93yuwdteO5T4Meta } from "/opt/build/repo/app/pages/university/[...slug].vue?macro=true";
import { default as indexGlDpIgN23zMeta } from "/opt/build/repo/app/pages/university/index.vue?macro=true";
import { default as algemeenMkigZkisYIMeta } from "/opt/build/repo/app/pages/voorwaarden/algemeen.vue?macro=true";
import { default as data_verantwoording2JG1ihyeMVMeta } from "/opt/build/repo/app/pages/voorwaarden/data_verantwoording.vue?macro=true";
import { default as indexFb76UkCSvbMeta } from "/opt/build/repo/app/pages/voorwaarden/index.vue?macro=true";
import { default as privacysCA7A5AdyfMeta } from "/opt/build/repo/app/pages/voorwaarden/privacy.vue?macro=true";
import { default as voorwaarden0AJWb1sJfMMeta } from "/opt/build/repo/app/pages/voorwaarden.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accounton9HXKIm8WMeta?.name,
    path: "/account",
    meta: accounton9HXKIm8WMeta || {},
    component: () => import("/opt/build/repo/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-change-password",
    path: "change-password",
    meta: change_45passwordieN0Bq7BQGMeta || {},
    component: () => import("/opt/build/repo/app/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/opt/build/repo/app/pages/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "artikelen-slug",
    path: "/artikelen/:slug()",
    meta: indexySMGYhsb0bMeta || {},
    component: () => import("/opt/build/repo/app/pages/artikelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "artikelen",
    path: "/artikelen",
    meta: indexyX3mmPjpJTMeta || {},
    component: () => import("/opt/build/repo/app/pages/artikelen/index.vue").then(m => m.default || m)
  },
  {
    name: "artikelen-thema-themeslug",
    path: "/artikelen/thema/:themeslug()",
    component: () => import("/opt/build/repo/app/pages/artikelen/thema/[themeslug].vue").then(m => m.default || m)
  },
  {
    name: "artikelen-zoeken",
    path: "/artikelen/zoeken",
    meta: zoekenWraIWPWZmHMeta || {},
    component: () => import("/opt/build/repo/app/pages/artikelen/zoeken.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordo19rVsgnAyMeta || {},
    component: () => import("/opt/build/repo/app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/login",
    meta: loginCOcTRg2g5mMeta || {},
    component: () => import("/opt/build/repo/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/signup",
    meta: signupnZr5JCOvb0Meta || {},
    component: () => import("/opt/build/repo/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/verify-email",
    meta: verify_45emailwi0CVOgvscMeta || {},
    component: () => import("/opt/build/repo/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "calculatoren-slug",
    path: "/calculatoren/:slug(.*)*",
    meta: _91_46_46_46slug_93hZadi4L8yZMeta || {},
    component: () => import("/opt/build/repo/app/pages/calculatoren/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campagne-bronkhorst",
    path: "/campagne/bronkhorst",
    meta: bronkhorstHP88keyCTpMeta || {},
    component: () => import("/opt/build/repo/app/pages/campagne/bronkhorst.vue").then(m => m.default || m)
  },
  {
    name: "campagne-ddop",
    path: "/campagne/ddop",
    meta: ddopgWsCQ1m1h6Meta || {},
    component: () => import("/opt/build/repo/app/pages/campagne/ddop.vue").then(m => m.default || m)
  },
  {
    name: "campagne-supertester",
    path: "/campagne/supertester",
    meta: supertesterai16RR25z0Meta || {},
    component: () => import("/opt/build/repo/app/pages/campagne/supertester.vue").then(m => m.default || m)
  },
  {
    name: "collectief-bronkhorst",
    path: "/collectief/bronkhorst",
    component: () => import("/opt/build/repo/app/pages/collectief/bronkhorst.vue").then(m => m.default || m)
  },
  {
    name: "collectief-ddop-demo",
    path: "/collectief/ddop-demo",
    component: () => import("/opt/build/repo/app/pages/collectief/ddop-demo.vue").then(m => m.default || m)
  },
  {
    name: "collectief-supertester",
    path: "/collectief/supertester",
    component: () => import("/opt/build/repo/app/pages/collectief/supertester.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/build/repo/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "favorieten",
    path: "/favorieten",
    component: () => import("/opt/build/repo/app/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/opt/build/repo/app/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "goals",
    path: "/goals",
    meta: goalsvGrhNf24SbMeta || {},
    component: () => import("/opt/build/repo/app/pages/goals.vue").then(m => m.default || m)
  },
  {
    name: huishoudenaWlIXMiRaZMeta?.name,
    path: "/huishouden",
    meta: huishoudenaWlIXMiRaZMeta || {},
    component: () => import("/opt/build/repo/app/pages/huishouden.vue").then(m => m.default || m),
    children: [
  {
    name: "huishouden-adres",
    path: "adres",
    component: () => import("/opt/build/repo/app/pages/huishouden/adres.vue").then(m => m.default || m)
  },
  {
    name: "huishouden",
    path: "",
    component: () => import("/opt/build/repo/app/pages/huishouden/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index9QtG0JCDh2Meta || {},
    component: () => import("/opt/build/repo/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-for-test-site",
    path: "/login-for-test-site",
    component: () => import("/opt/build/repo/app/pages/login-for-test-site.vue").then(m => m.default || m)
  },
  {
    name: mijn_45woningRQTXE4MeowMeta?.name,
    path: "/mijn-woning",
    meta: mijn_45woningRQTXE4MeowMeta || {},
    component: () => import("/opt/build/repo/app/pages/mijn-woning.vue").then(m => m.default || m),
    children: [
  {
    name: "mijn-woning-space-calculator",
    path: ":space()/:calculator()",
    meta: index2VknEjogkRMeta || {},
    component: () => import("/opt/build/repo/app/pages/mijn-woning/[space]/[calculator]/index.vue").then(m => m.default || m)
  },
  {
    name: "mijn-woning-space",
    path: ":space()",
    component: () => import("/opt/build/repo/app/pages/mijn-woning/[space]/index.vue").then(m => m.default || m)
  },
  {
    name: "mijn-woning",
    path: "",
    component: () => import("/opt/build/repo/app/pages/mijn-woning/index.vue").then(m => m.default || m)
  },
  {
    name: "mijn-woning-samenstellen-space",
    path: "samenstellen/:space()",
    component: () => import("/opt/build/repo/app/pages/mijn-woning/samenstellen/[space]/index.vue").then(m => m.default || m)
  },
  {
    name: "mijn-woning-samenstellen",
    path: "samenstellen",
    component: () => import("/opt/build/repo/app/pages/mijn-woning/samenstellen/index.vue").then(m => m.default || m)
  },
  {
    name: "mijn-woning-samenstellen-warmtevraag",
    path: "samenstellen/warmtevraag",
    meta: warmtevraagH0lLikUMYcMeta || {},
    component: () => import("/opt/build/repo/app/pages/mijn-woning/samenstellen/warmtevraag.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexwF8nRBU3h2Meta || {},
    component: () => import("/opt/build/repo/app/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: plannen7DtYZnORIjMeta?.name,
    path: "/plannen",
    component: () => import("/opt/build/repo/app/pages/plannen.vue").then(m => m.default || m),
    children: [
  {
    name: "plannen-bad",
    path: "bad",
    component: () => import("/opt/build/repo/app/pages/plannen/bad.vue").then(m => m.default || m)
  },
  {
    name: "plannen-douche",
    path: "douche",
    component: () => import("/opt/build/repo/app/pages/plannen/douche.vue").then(m => m.default || m)
  },
  {
    name: "plannen-huishouden",
    path: "huishouden",
    component: () => import("/opt/build/repo/app/pages/plannen/huishouden.vue").then(m => m.default || m)
  },
  {
    name: "plannen",
    path: "",
    component: () => import("/opt/build/repo/app/pages/plannen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/opt/build/repo/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: "profiel",
    path: "/profiel",
    component: () => import("/opt/build/repo/app/pages/profiel.vue").then(m => m.default || m),
    children: [
  {
    name: "profiel-create",
    path: "create",
    component: () => import("/opt/build/repo/app/pages/profiel/create/index.vue").then(m => m.default || m)
  },
  {
    name: "profiel-create-woning",
    path: "create/woning",
    component: () => import("/opt/build/repo/app/pages/profiel/create/woning/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "university-slug",
    path: "/university/:slug(.*)*",
    meta: _91_46_46_46slug_93yuwdteO5T4Meta || {},
    component: () => import("/opt/build/repo/app/pages/university/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "university",
    path: "/university",
    component: () => import("/opt/build/repo/app/pages/university/index.vue").then(m => m.default || m)
  },
  {
    name: voorwaarden0AJWb1sJfMMeta?.name,
    path: "/voorwaarden",
    component: () => import("/opt/build/repo/app/pages/voorwaarden.vue").then(m => m.default || m),
    children: [
  {
    name: "voorwaarden-algemeen",
    path: "algemeen",
    component: () => import("/opt/build/repo/app/pages/voorwaarden/algemeen.vue").then(m => m.default || m)
  },
  {
    name: "voorwaarden-data_verantwoording",
    path: "data_verantwoording",
    component: () => import("/opt/build/repo/app/pages/voorwaarden/data_verantwoording.vue").then(m => m.default || m)
  },
  {
    name: "voorwaarden",
    path: "",
    component: () => import("/opt/build/repo/app/pages/voorwaarden/index.vue").then(m => m.default || m)
  },
  {
    name: "voorwaarden-privacy",
    path: "privacy",
    component: () => import("/opt/build/repo/app/pages/voorwaarden/privacy.vue").then(m => m.default || m)
  }
]
  }
]