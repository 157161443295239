import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45test_45env_45blockade_45global from "/opt/build/repo/app/middleware/01.testEnvBlockade.global.ts";
import _02_45identity_45global from "/opt/build/repo/app/middleware/02.identity.global.ts";
import _03_45profile_45global from "/opt/build/repo/app/middleware/03.profile.global.ts";
import _04_45onboarding_45global from "/opt/build/repo/app/middleware/04.onboarding.global.ts";
import measures_45global from "/opt/build/repo/app/middleware/measures.global.ts";
import preview_45global from "/opt/build/repo/app/middleware/preview.global.ts";
import savecoupon_45global from "/opt/build/repo/app/middleware/savecoupon.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45test_45env_45blockade_45global,
  _02_45identity_45global,
  _03_45profile_45global,
  _04_45onboarding_45global,
  measures_45global,
  preview_45global,
  savecoupon_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/opt/build/repo/app/middleware/authenticated.ts"),
  "coupon-required": () => import("/opt/build/repo/app/middleware/couponRequired.ts"),
  unauthenticated: () => import("/opt/build/repo/app/middleware/unauthenticated.ts")
}