import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import api_slUmq5TON0 from "/opt/build/repo/app/plugins/api.ts";
import matomo_analytics_client_YAZEmBo9Li from "/opt/build/repo/app/plugins/matomo-analytics.client.ts";
import posthog_client_8TXiKjlKOS from "/opt/build/repo/app/plugins/posthog.client.ts";
import sentry_client_a4hAB0MOGi from "/opt/build/repo/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  api_slUmq5TON0,
  matomo_analytics_client_YAZEmBo9Li,
  posthog_client_8TXiKjlKOS,
  sentry_client_a4hAB0MOGi
]