import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { FormKitNode } from '@formkit/core'
import posthog from 'posthog-js'
import type { FormErrors } from '~~/types/FormErrors'

export interface Collective {
  name: string
  slug: string
}
export type IdentityProps = {
  subscription_type: 'plus' | 'premium' | 'free'
  collectives: Collective[]
  super_admin: boolean
}
export type IdentityObject = {
  token: string
  email: string
  firstName: string
  lastName: string
  props: string
}

export const identityStore = defineStore('identity', () => {
  const token = ref('')
  const email = ref('')
  const firstName = ref('')
  const lastName = ref('')
  const props = ref<IdentityProps | undefined>()
  const state = ref<'loggedOut' | 'loggingIn' | 'loggedIn' | 'loggingOut'>('loggedOut')
  const loggedIn = computed(() => state.value === 'loggedIn')

  const displayFirstName = computed(() => {
    return firstName.value || email.value
  })
  const displayFullName = computed(() => {
    return `${firstName.value} ${lastName.value}` || email.value
  })

  const error = ref<FormErrors | null>(null)

  const storeIdentityObjectAsCookie = (identityObject: IdentityObject): IdentityObject => {
    const cookie = useIdentityCookie()
    if (process.server || !cookie)
      return identityObject

    cookie.value = identityObject
    return identityObject
  }

  const deleteIdentityObjectCookie = () => {
    const cookie = useIdentityCookie()
    if (process.server || !cookie)
      return true
    cookie.value = null
    return true
  }
  const getCognitoService = async () => {
    return (await import('@/services/CognitoService')).default
  }

  const setToken = async (result: IdentityObject | null) => {
    token.value = result?.token ?? ''
    email.value = result?.email ?? ''
    firstName.value = result?.firstName ?? ''
    lastName.value = result?.lastName ?? ''
    props.value = result?.props ? JSON.parse(result?.props) : undefined
    if (result === null) {
      deleteIdentityObjectCookie()
      state.value = 'loggedOut'
    }
    else {
      state.value = 'loggedIn'
      storeIdentityObjectAsCookie(result)
    }
  }

  const getSession = async () => {
    try {
      // TODO: check this!
      if (process.server) {
        const cookie = useIdentityCookie()
        if (cookie && cookie.value)
          await setToken(cookie.value)

        return
      }
      const cognitoService = await getCognitoService()
      const result = await cognitoService.getSession()
      await setToken(result)
    }
    catch (newError: any) {
      error.value = {
        errors: [newError.message],
      }
    }
  }

  const logIn = async (email: string, password: string) => {
    state.value = 'loggingIn'
    error.value = null
    try {
      const cognitoService = await getCognitoService()
      const token = await cognitoService.logIn(email, password)
      await setToken(token)
      posthog.capture('login')
    }
    catch (reason: any) {
      error.value = reason
      state.value = 'loggedOut'
      throw reason
    }
  }

  const logOut = async (): Promise<void> => {
    state.value = 'loggingOut'
    posthog.capture('logout')
    posthog.reset()
    await navigateTo('/login')
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const cognitoService = await getCognitoService()
      await cognitoService.logout()
      await setToken(null)
      await refreshNuxtData()
      resolve()
    })
  }

  const changePassword = async (oldPassword: string, newPassword: string) => {
    const cognitoService = await getCognitoService()
    return cognitoService.changePassword(oldPassword, newPassword)
  }

  const forgotPassword = async (email: string) => {
    const cognitoService = await getCognitoService()
    cognitoService.forgotPassword(email).then(() => {
      return true
    })
  }

  const confirmPassword = async (email: string, verificationCode: string, newPassword: string) => {
    const cognitoService = await getCognitoService()
    await cognitoService.confirmPassword(email, verificationCode, newPassword)
    return true
  }

  const togglePasswordView = (node: FormKitNode) => {
    node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eye-slash' : 'eye'
    node.props.type = node.props.type === 'password' ? 'text' : 'password'
  }

  return {
    token,
    displayFirstName,
    displayFullName,
    email,
    firstName,
    lastName,
    loggedIn,
    error,
    state,
    props,
    getSession,
    logIn,
    logOut,
    changePassword,
    forgotPassword,
    confirmPassword,
    togglePasswordView,
  }
})
